
import Vue, { PropType } from 'vue';
import { V1EntitiesAnalyticsManagementSurveysSurvey } from '@/services/api/tsxass';
import TTSimpleCheckbox from '@/components/ui/TTSimpleCheckbox.vue';

interface SurveySelectItem {
  name: string,
  id: number | null,
  dateStart?: string,
}

type ValueType = V1EntitiesAnalyticsManagementSurveysSurvey | number
  | V1EntitiesAnalyticsManagementSurveysSurvey[] | number[] | null;

export default Vue.extend({
  name: 'SurveySelect',

  components: {
    TTSimpleCheckbox,
  },

  props: {
    value: {
      type: [Number, Object, Array, null] as PropType<ValueType>,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default(): string {
        return this.$t('analytics.feedback.selectSurveyLabel') as string;
      },
    },
    surveys: {
      type: Array as PropType<V1EntitiesAnalyticsManagementSurveysSurvey[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: [Boolean, String, Object],
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    // В списке опций есть "Все опросы"
    hasAllSurveysItem: {
      type: Boolean,
      default: false,
    },
    // Если выбраны все значения - возвращать значение null
    nullIfAllSelected: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    internalValue: {
      get(): ValueType {
        if (this.multiple) {
          if (this.value === null && this.nullIfAllSelected) {
            return this.returnObject
              ? this.surveys
              : this.surveys.map((item) => item.id);
          }
          return this.value;
        }
        return this.value;
      },
      set(value: ValueType) {
        if (this.multiple) {
          if (this.nullIfAllSelected
            && (value as number[] | V1EntitiesAnalyticsManagementSurveysSurvey[]).length === this.surveys.length) {
            this.$emit('input', null);
          } else {
            this.$emit('input', value);
          }
        } else {
          this.$emit('input', value);
        }
      },
    },
    selectedItemsArray(): V1EntitiesAnalyticsManagementSurveysSurvey[] | number[] {
      const items = Array.isArray(this.internalValue)
        ? this.internalValue
        : this.internalValue ? [this.internalValue] : [];
      return items as V1EntitiesAnalyticsManagementSurveysSurvey[] | number[];
    },
    selectedItemIds(): number[] {
      return this.returnObject
        ? (this.selectedItemsArray as V1EntitiesAnalyticsManagementSurveysSurvey[]).map((survey) => survey.id)
        : this.selectedItemsArray as number[];
    },
    // Опция "Все опросы" активна
    allIsSelected(): boolean {
      return this.selectedItemsArray.length === this.surveys.length;
    },
    surveySelectItems(): SurveySelectItem[] {
      return this.surveys.map((survey) => ({
        name: survey.name,
        id: survey.id,
        withBenchAvg: survey.withBenchAvg,
        dateStart: this.$dayjs(survey.dateStart).format('DD.MM.YYYY'),
      }));
    },
  },

  methods: {
    clearSelected() {
      this.internalValue = [];
    },
    selectAll() {
      if (this.returnObject) {
        this.internalValue = this.surveys;
      } else {
        this.internalValue = this.surveys.map((item) => item.id);
      }
    },
    onCheckboxInput(item: V1EntitiesAnalyticsManagementSurveysSurvey, value: boolean) {
      const arrayValue = Array.isArray(this.internalValue) ? this.internalValue : [];

      let selectedUsers;
      if (this.returnObject) {
        selectedUsers = value
          ? [...(arrayValue as V1EntitiesAnalyticsManagementSurveysSurvey[]), item]
          : (arrayValue as V1EntitiesAnalyticsManagementSurveysSurvey[]).filter((survey) => survey.id !== item.id);
      } else {
        selectedUsers = value
          ? [...(arrayValue as number[]), item.id]
          : (arrayValue as number[]).filter((surveyId) => surveyId !== item.id);
      }
      this.internalValue = selectedUsers;
    },
    onAllSurveysOptionClick() {
      if (this.allIsSelected || this.selectedItemsArray.length) {
        this.clearSelected();
      } else {
        this.selectAll();
      }
    },
  },
});
