
import { defineComponent, PropType } from 'vue';
import { tsxassApi } from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import {
  V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsQuestion,
  V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsAnswer,
} from '@/services/api/tsxass';
import TTLoader from '@/components/ui/TTLoader.vue';

export default defineComponent({
  name: 'CommentsFeedWidget',

  components: {
    TTLoader,
  },

  props: {
    surveyIds: {
      type: [Array, null] as PropType<number[] | null>,
      default: () => null,
    },
    userId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      chartData: {
        questions: [] as V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsQuestion[],
        answers: [] as V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsAnswer[],
      },
      loading: false,
      selectedQuestionIndex: 0,
    };
  },

  computed: {
    selectedQuestion(): V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsQuestion | null {
      return this.chartData.questions[this.selectedQuestionIndex] || null;
    },
    selectedQuestionAnswers(): V1EntitiesAnalyticsFeedbackOpenQuestionListCommentsAnswer[] {
      if (!this.selectedQuestion) {
        return [];
      }

      return this.chartData.answers.filter((answer) => answer.questionId === this.selectedQuestion?.id);
    },
  },

  watch: {
    surveyIds: {
      immediate: true,
      handler() {
        this.loadChartData();
      },
    },
    userId() {
      this.loadChartData();
    },
  },

  methods: {
    async loadChartData() {
      try {
        this.loading = true;
        const { data } = await tsxassApi.getV1AnalyticsFeedbackOpenQuestionsListComments(
          this.surveyIds || undefined,
          this.userId || undefined,
        );
        this.chartData = data;
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
